import React, { Fragment } from "react"
import { Styled } from "theme-ui"

/**
 * Change the content to add your own bio
 */

export default () => (
  <Fragment>
    <Styled.a href="http://ghishadow.com/">Suraj notes in shadows</Styled.a>

  </Fragment>
)
